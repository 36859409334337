<template>
  <template v-if="me">
    <v-list-item>
      <template #prepend>
        <app-avatar :full-name="me.fullName" :src="me.avatarUrl" />
      </template>

      <v-list-item-title>{{ me.fullName }}</v-list-item-title>
      <v-list-item-subtitle>
        {{ me.email }}
      </v-list-item-subtitle>

      <template #append>
        <v-btn icon="ph:sign-out" variant="text" @click="signOut" />
      </template>
    </v-list-item>
  </template>
</template>

<script lang="ts" setup>
const { me } = useMe();
const { signOut } = useAuthenticationSignIn();
</script>
